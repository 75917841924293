<template>
  <div>
    <!--  <Navbar /> -->
    <div v-if="loading || isError" class="loader-container"></div>
    <div class="login-container">
      <div class="page-wrapper">
        <div class="page-img">
          <img
            src="/img/angel.png"
            style="width: 200px; margin-right: 70px"
            alt=""
          />
        </div>
        <div class="signup-options2">
          <div style="color: #a8a3a3">You were referred to Grupa by</div>
          <!-- <a :href="$linkedinRedirectURL"> -->
          <!--  :href="`mailto:matchOps@grupa.io?Subject=Interested in ${startup.name}`" -->
          <!-- @click="getVCFromSlugFunc()" -->

          <div
            @click="SaveVCReferral()"
            :class="
              isFound
                ? 'connect-btn2'
                : loading
                ? 'connect-btn-white'
                : 'connect-btn-red'
            "
            style="justify-content: space-around; flex-direction: row"
          >
            <!-- <div
              style="
                background-color: #fff;
                width: 40px;
                padding: 5px;
                border-radius: 10px;
              "
            >
              <img
                src="/img/grupa_logo.png"
                width="30"
                style="padding-right: 3px"
              />
            </div> -->
            <div
              v-show="!loading"
              style="padding-left: 0px; font-weight: bolder"
            >
              VC :

              <span style="padding-left: 10px" v-show="isFound">{{
                vcData.name
              }}</span>

              <span style="padding-left: 10px" v-show="!isFound"
                >NOT FOUND</span
              >
            </div>

            <span v-show="loading">
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 10px; margin-top: 0px"
                class="button-spinner"
              />
              <span style="font-size: 10px">verifying VC account...</span>
            </span>

            <div
              v-show="!loading"
              style="
                margin-left: 13%;
                background-color: #fff;
                border-radius: 10px;
                padding: 5px;
                padding-left: 8px;
                padding-right: 8px;

                font-size: 12px;
                color: #000;
                font-weight: 600;
              "
            >
              {{ isFound ? "JOIN NOW" : "CONTINUE" }}
            </div>
          </div>
        </div>
        <div class="google-signup">
          <!--  <GoogleSignIn /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
//import GoogleSignIn from "../googleLogin";

import { getVCFromSlug2 } from "@/api";
import Axios from "axios";
import { isLong } from "long";

export default {
  data: () => ({
    slug: "",
    vcData: [],
    loading: true,
    isError: false,
    isFound: false,
  }),
  created() {
    this.slug = this.$route.params.slug;
    this.getVCFromSlugFunc();
  },
  methods: {
    SaveVCReferral() {
      if (this.isFound) {
        localStorage.setItem("vcs", JSON.stringify(this.vcData));
      }
      this.$router.push({ name: "login" });
    },
    getVCFromSlugFunc() {
      let baseURL = process.env.VUE_APP_BACKEND_API;

      let payload = {
        slug: this.slug,
      };

      /* */
      let url = `${baseURL}/onboarding/client/get/vc/${payload.slug}`;
      Axios.get(`${url}`)
        .then((data) => {
          this.loading = false;
          this.isError = false;
          let vcs = data.data.vcs;
          this.isFound = true;
          this.vcData = vcs;

          this.SaveVCReferral();
        })
        .catch((error) => {
          this.isFound = false;

          this.loading = false;
          this.isError = true;
          console.log("error", error);
          localStorage.removeItem("vcs");
        }); /* */
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Moderat";
  font-style: normal;
  font-weight: normal;
}
.login-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 80px);
}
.page-wrapper {
  width: 410px;
}
.signup-options2 {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.signup-options2 a {
  text-decoration: none;
}
.connect-btn2 {
  background: #0288d1;
  box-shadow: 1px 2px 5px rgba(21, 23, 26, 0.2);
  border-radius: 4px;
  padding: 9px;
  width: 410px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  align-items: center;
}

.connect-btn-white {
  background: #e0e2e3;
  box-shadow: 1px 2px 5px rgba(21, 23, 26, 0.2);
  border-radius: 4px;
  padding: 9px;
  width: 410px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #232020;
  cursor: pointer;
  align-items: center;
}

.connect-btn-red {
  background: #92050c;
  box-shadow: 1px 2px 5px rgba(21, 23, 26, 0.2);
  border-radius: 4px;
  padding: 9px;
  width: 410px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  align-items: center;
}

.connect-btn2:hover {
  box-shadow: 4px 8px 15px rgba(21, 23, 26, 0.2);
  border-radius: 4px;
  border: 1px solid #080e448e;
}
.connect-btn2 img {
  margin-right: 5px;
}
.page-img {
  height: 217px;
}
</style>
