var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading || _vm.isError
      ? _c("div", { staticClass: "loader-container" })
      : _vm._e(),
    _c("div", { staticClass: "login-container" }, [
      _c("div", { staticClass: "page-wrapper" }, [
        _vm._m(0),
        _c("div", { staticClass: "signup-options2" }, [
          _c("div", { staticStyle: { color: "#a8a3a3" } }, [
            _vm._v("You were referred to Grupa by"),
          ]),
          _c(
            "div",
            {
              class: _vm.isFound
                ? "connect-btn2"
                : _vm.loading
                ? "connect-btn-white"
                : "connect-btn-red",
              staticStyle: {
                "justify-content": "space-around",
                "flex-direction": "row",
              },
              on: {
                click: function ($event) {
                  return _vm.SaveVCReferral()
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loading,
                      expression: "!loading",
                    },
                  ],
                  staticStyle: {
                    "padding-left": "0px",
                    "font-weight": "bolder",
                  },
                },
                [
                  _vm._v("\n            VC :\n\n            "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isFound,
                          expression: "isFound",
                        },
                      ],
                      staticStyle: { "padding-left": "10px" },
                    },
                    [_vm._v(_vm._s(_vm.vcData.name))]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isFound,
                          expression: "!isFound",
                        },
                      ],
                      staticStyle: { "padding-left": "10px" },
                    },
                    [_vm._v("NOT FOUND")]
                  ),
                ]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("img", {
                    staticClass: "button-spinner",
                    staticStyle: { "margin-left": "10px", "margin-top": "0px" },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  }),
                  _c("span", { staticStyle: { "font-size": "10px" } }, [
                    _vm._v("verifying VC account..."),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loading,
                      expression: "!loading",
                    },
                  ],
                  staticStyle: {
                    "margin-left": "13%",
                    "background-color": "#fff",
                    "border-radius": "10px",
                    padding: "5px",
                    "padding-left": "8px",
                    "padding-right": "8px",
                    "font-size": "12px",
                    color: "#000",
                    "font-weight": "600",
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.isFound ? "JOIN NOW" : "CONTINUE") +
                      "\n          "
                  ),
                ]
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "google-signup" }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-img" }, [
      _c("img", {
        staticStyle: { width: "200px", "margin-right": "70px" },
        attrs: { src: "/img/angel.png", alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }